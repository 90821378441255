import { Component, OnInit } from '@angular/core';
import { APIS, SIDEBAR, USER_DETAILS } from 'app/common/constants';
import { AuthUserService } from 'app/core/services/auth-user.service';
import { AlertService } from 'app/core/services/alert.service';
import { HttpService } from 'app/core/services/http.service';
import { environment } from '../../environments/environment';
import * as $ from 'jquery';

export interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  iconPath: string,
  hide:boolean
}

export const ROUTES: RouteInfo[] = [
  //{ path: '/dashboard', title: 'Dashboard', icon: 'fa-bank', class: '' },
  // { path: '/icons',         title: 'Icons',             icon:'nc-diamond',    class: '' },
  { path: '/user/users', title: 'Users', icon: 'fa-user', class: '', iconPath: '', hide: false },
  { path: '/user/add-user', title: 'Add User', icon: 'fa-user', class: '', iconPath: '', hide: true },
  { path: '/user/import', title: 'Import', icon: 'fa-upload', class: '', iconPath: '' , hide: false },
  { path: '/user/po-selection', title: 'CREATE INSPECTIONS', icon: 'fa-list-check', class: '',
    iconPath: '', hide: !SIDEBAR['show-po-selection'] },
  { path: '/user/product', title: 'Product', icon: 'fa-upload', class: '',
    iconPath: '../../assets/img/measure.png', hide:false },
  { path: '/user/selectinspection', title: 'Select Inspection', icon: 'fa-list-alt', class: '',
    iconPath: '' , hide: false },
  { path: '/user/packaging', title: 'Packaging', icon: 'fa-check-square', class: '',
    iconPath: '../../assets/icons/box.svg', hide: !SIDEBAR['show-packaging'] },
  { path: '/user/inspections', title: SIDEBAR['inspection-plan-title'], icon: 'fa-universal-access ',
    class: '', iconPath: '../../assets/icons/'+SIDEBAR['inspections-icon'], hide: false },
  { path: '/user/downloads', title: 'Downloads', icon: 'fa-book', class: '', iconPath: '',
    hide: !SIDEBAR['show-downloads'].show },
  { path: '/user/inspection-detail', title: 'AQL Inspection Report', icon: 'fa-universal-access ', class: '',
    iconPath: '../../assets/icons/t-shirt.svg', hide: true },
  { path: '/user/skipped-inspections', title: 'SKIPPED INSPECTIONS', icon: 'fa-fast-forward', class: '',
    iconPath: '', hide: !SIDEBAR['show-skip-inspections'] },
];

@Component({
  moduleId: module.id,
  selector: 'sidebar-cmp',
  templateUrl: 'sidebar.component.html', styleUrls: ['style.css'],
})

export class SidebarComponent implements OnInit {
  showReset;
  public menuItems: any[];
  constructor(private authService: AuthUserService, private alertService: AlertService,
              private httpService: HttpService) {}
  ngOnInit() {
    let role = parseInt(localStorage.getItem('role'));
    this.showReset = environment.enableReset && role == 1;
    this.authService.authenticaed.subscribe((res) => {
      this.menuItems = ROUTES.filter(menuItem => !menuItem.hide);
      if(role > 1){
        this.menuItems.splice(0,1);
      }
    });
  }

  sidebar_toggle() {
    $('.wrapper').toggleClass('toggleDiv');
  }

  reset() {
    this.alertService.confirmPopUp(
      { type: 'warning', title: 'Reset',
        text: 'Are you sure you want to reset the Demo environment. This will return the ' +
        'environment to the default settings and you will lose all inspections you have made.' }
    ).then(res => {
      if (res.value) {
        this.httpService.getData(APIS.RESET, { udc_id: localStorage.getItem('location') }).subscribe((res) => {
          if (res.ok) {
            this.alertService.successToast('Environment reset in progress. A message will be sent ' +
              'to the #qclive-builds-and-deployments channel in Slack when complete. It may take up to 20 minutes.');
          }
        });
      }
    });
  }
}
